<script setup>
import {Link, usePage} from "@inertiajs/vue3";
import StageTabContent from "@/Components/StageTabContent.vue";
import * as SortableVue3 from "sortablejs-vue3";
import {computed} from "vue";
import {router} from "@inertiajs/vue3";
const { Sortable } = SortableVue3;

const page = usePage();
const stages = computed(() => page.props.event.stages);
const currentStage = computed(() => page.props.stage);

function updateSort(e) {
    const updatedOrder = stages.value;
    const movedItem = updatedOrder.splice(e.oldIndex, 1)[0];
    updatedOrder.splice(e.newIndex, 0, movedItem);

    const dataToUpdate = updatedOrder.map((item, idx) => {
        return {
            id: item.id,
            order: idx + 1
        }
    });

    router.post(route('events.stages.sort', page.props.event.slug), {
        stages: dataToUpdate
    }, {
        preserveScroll: true,
        preserveState: true
    });
}
</script>

<template>
    <Sortable
        :list="stages"
        item-key="slug"
        tag="ol"
        :options="{group: 'stages', animation: 150, handle: '.stage-handle', dragClass: 'opacity-0'}"
        class="overflow-hidden md:flex flex-grow"
        @update="updateSort"
    >
        <template #item="{element: stage, index: idx}">
            <li class="relative overflow-hidden md:flex-1">
                <div :class="[idx === 0 ? 'rounded-t-md border-b-0' : '', idx === stages.length - 1 ? 'rounded-b-md border-t-0' : '', 'overflow-hidden']">
                    <Link v-if="stage.completed" :href="route('events.stages.show', [$page.props.event.slug, stage.slug])" class="group">
                        <span class="absolute left-0 top-0 h-full w-1 md:bottom-0 md:top-auto md:h-1 md:w-full" aria-hidden="true"
                              :class="currentStage?.id === stage.id ? 'bg-primary' : 'group-hover:bg-gray-500'"/>
                        <span :class="[idx === 0 ? 'lg:pl-9' : 'md:pl-9', 'flex items-center px-4 md:px-0 pt-4 pb-3 font-medium']">
                            <span class="flex-shrink-0">
                                <span class="flex h-10 w-10 items-center justify-center rounded-full bg-dark border-2"
                                      :class="currentStage?.id === stage.id ? 'border-primary' : 'border-gray-400'">
                                    <svg class="h-6 w-6" :class="currentStage?.id === stage.id ? 'text-primary' : 'text-gray-400'" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5"></path>
                                    </svg>
                                </span>
                            </span>
                            <StageTabContent :stage="stage" />
                        </span>
                    </Link>
                    <Link v-else-if="currentStage?.id === stage.id" :href="route('events.stages.show', [$page.props.event.slug, stage.slug])" aria-current="step" class="group">
                        <span class="absolute left-0 top-0 h-full w-1 bg-primary md:bottom-0 md:top-auto md:h-1 md:w-full" aria-hidden="true" />
                        <span :class="[idx === 0 ? 'lg:pl-9' : 'md:pl-9', 'flex items-center px-4 md:px-0 pt-4 pb-3 font-medium']">
                            <span class="flex-shrink-0">
                                <span class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-primary">
                                    <span class="text-primary">{{ idx + 1 }}</span>
                                </span>
                            </span>
                            <StageTabContent :stage="stage" />
                        </span>
                    </Link>
                    <Link v-else :href="route('events.stages.show', [$page.props.event.slug, stage.slug])" class="group">
                        <span class="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-500 md:bottom-0 md:top-auto md:h-1 md:w-full" aria-hidden="true" />
                        <span :class="[idx === 0 ? 'lg:pl-9' : 'md:pl-9', 'flex items-center px-4 md:px-0 pt-4 pb-3 font-medium']">
                            <span class="flex-shrink-0">
                                <span class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-400">
                                    <span class="text-gray-400">{{ idx + 1 }}</span>
                                </span>
                            </span>
                            <StageTabContent :stage="stage" />
                        </span>
                    </Link>
                    <!-- Separator -->
                    <div class="absolute inset-0 left-0 top-0 hidden w-3" :class="idx === 0 ? 'lg:block' : 'md:block'" aria-hidden="true">
                        <svg class="h-full w-full text-gray-700" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
                            <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke" />
                        </svg>
                    </div>
                </div>
            </li>
        </template>
    </Sortable>
</template>
