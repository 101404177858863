<script setup>
import { computed } from 'vue';
import { Link } from '@inertiajs/vue3';

const props = defineProps({
    href: String,
    active: Boolean,
    disabled: Boolean
});

const classes = computed(() => {
    let cls = props.active
        ? 'inline-flex items-center px-1 pt-1 border-b-2 border-primary text-sm font-medium leading-5 text-gray-100 focus:border-primary transition duration-150 ease-in-out'
        : 'inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-400 hover:text-gray-300 hover:border-gray-700 focus:text-gray-300 focus:border-gray-700 transition duration-150 ease-in-out';

    if (props.disabled) {
        cls = cls.split(" ").filter(c => !c.startsWith('hover:') && !c.startsWith('focus:')).join(" ");
    }

    cls += " focus:outline-none";
    return cls;
});
</script>

<template>
    <Link :href="href" :class="classes" :disabled="disabled">
        <slot />
    </Link>
</template>
