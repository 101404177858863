<script setup>
import DialogModal from "@/Components/DialogModal.vue";
import SecondaryButton from "@/Components/SecondaryButton.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import {useForm} from "@inertiajs/vue3";
import TextInput from "@/Components/TextInput.vue";
import InputLabel from "@/Components/InputLabel.vue";
import {nextTick, ref, watch} from "vue";
import InputError from "@/Components/InputError.vue";

const props = defineProps({
    showModal: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits({
    'close': () => true
})

const input = ref(null);
const form = useForm({
    name: '',
});

watch(() => props.showModal, val => {
    if (val) {
        nextTick(() => {
            input.value.focus();
        })
    } else {
        form.reset();
    }
})

const createEvent = () => {
    form.post(route('events.store'), {
        errorBag: 'createEvent',
        onSuccess: () => emit('close')
    })
}
</script>

<template>
    <form @submit.prevent="createEvent">
        <DialogModal :show="showModal" @close="$emit('close')">
            <template #title>
                Create New Pick'ems Event
            </template>

            <template #content>
                <!-- Name -->
                <div class="col-span-6 sm:col-span-4">
                    <InputLabel for="name" value="Event Name" />
                    <TextInput
                        id="name"
                        v-model="form.name"
                        type="text"
                        class="mt-1 block w-full"
                        required
                        ref="input"
                    />
                    <InputError :message="form.errors.name" class="mt-2" />
                </div>
            </template>

            <template #footer>
                <SecondaryButton @click="$emit('close')">
                    Cancel
                </SecondaryButton>

                <PrimaryButton
                    class="ms-3"
                    type="submit"
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                >
                    Create Event
                </PrimaryButton>
            </template>
        </DialogModal>
    </form>
</template>
