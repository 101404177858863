<script setup>
import {computed, nextTick, ref} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import Modal from "./Modal.vue";
import TextInput from "./TextInput.vue";
import SecondaryButton from "./SecondaryButton.vue";
import InputError from "./InputError.vue";
import DialogModal from "./DialogModal.vue";
import PrimaryButton from "./PrimaryButton.vue";
import InputLabel from "./InputLabel.vue";
import Checkbox from "./Checkbox.vue";
import ConfirmationModal from "./ConfirmationModal.vue";
import DangerButton from "./DangerButton.vue";

const {stage} = defineProps({
    stage: Object
});

const page = usePage();
const isActive = computed(() => page.props?.stage?.id === stage.id);

const editingStage = ref(false);
const input = ref(null);
let form;

const deletingStage = ref(false);

function startEditingStage() {
    form = useForm({
        name: stage.name,
        active: stage.active,
        completed: stage.completed,
        points_per_correct_team: stage.points_per_correct_team,
        points_per_correct_score: stage.points_per_correct_score,
        full_width: stage.full_width
    });
    editingStage.value = true;

    nextTick(() => {
        input.value.focus();
    });
}

function updateStage() {
    form.post(route('events.stages.update', [page.props.event.slug, stage.slug]), {
        errorBag: 'updateStage',
        onSuccess: () => {
            editingStage.value = false;
        }
    });
}

function destroyStage() {
    router.delete(route('events.stages.destroy', [page.props.event.slug, stage.slug]), {
        onSuccess: () => {
            deletingStage.value = false;
        }
    });
}
</script>

<template>
    <span class="flex-grow flex justify-between items-center mr-4 overflow-hidden">
        <span class="ml-4 mt-0.5 flex min-w-0 flex-col">
            <span class="font-medium" :class="isActive ? 'text-primary' : 'text-gray-300'">
                {{ stage.name }}
                <span v-if="stage.active && page.props.admin.mode"
                      class="bg-gray-500 px-1 py-0.5 rounded-md text-xs text-white ml-1">
                    active
                </span>
            </span>
<!--            <span class="text-sm font-medium" :class="isActive ? 'text-gray-300' : 'text-gray-500'">-->
<!--                description?-->
<!--            </span>-->
        </span>
        <span class="w-[84px] mr-[-84px] group-hover:mr-0 transition-all ease-in-out duration-300 flex"
              v-if="$page.props.admin.mode">
            <button type="button" @click.prevent.stop
                    class="stage-handle inline-flex items-center p-1 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-400 bg-dark hover:text-gray-300 focus:outline-none transition ease-in-out duration-150">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="size-4">
                    <path fill-rule="evenodd" d="M2 4.75A.75.75 0 0 1 2.75 4h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 4.75ZM2 10a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 10Zm0 5.25a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />
                </svg>
            </button>
            <button type="button" @click.prevent.stop="startEditingStage"
                    class="inline-flex items-center p-1 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-400 bg-dark hover:text-gray-300 focus:outline-none transition ease-in-out duration-150">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="size-4">
                    <path d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                    <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
                </svg>
            </button>
            <button type="button" @click.prevent.stop="deletingStage = true"
                    class="inline-flex items-center p-1 border border-transparent text-sm leading-4 font-medium rounded-md text-red-800 bg-dark hover:text-red-600 focus:outline-none transition ease-in-out duration-150">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="size-4">
                    <path fill-rule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clip-rule="evenodd" />
                </svg>
            </button>
        </span>
    </span>

    <teleport to="#portal">
        <form @submit.prevent="updateStage">
            <DialogModal :show="editingStage" @close="editingStage = false">
                <template #title>
                    Updating stage called {{ stage.name }}
                </template>

                <template #content>
                    <div class="space-y-4">
                        <div class="col-span-6 sm:col-span-4">
                            <InputLabel for="name" value="Stage Name" />
                            <TextInput
                                id="name"
                                v-model="form.name"
                                type="text"
                                class="mt-1 block w-full"
                                required
                                ref="input"
                            />
                            <InputError :message="form.errors.name" class="mt-2" />
                        </div>
                        <div class="col-span-6 sm:col-span-4 grid grid-cols-2 gap-4">
                            <div>
                                <InputLabel for="points_per_correct_team" value="Points per Correct Team Prediction" />
                                <TextInput
                                    id="points_per_correct_team"
                                    v-model="form.points_per_correct_team"
                                    type="number"
                                    class="mt-1 block w-full"
                                />
                                <InputError :message="form.errors.points_per_correct_team" class="mt-2" />
                            </div>
                            <div>
                                <InputLabel for="points_per_correct_score" value="Points per Correct Score Prediction" />
                                <TextInput
                                    id="points_per_correct_score"
                                    v-model="form.points_per_correct_score"
                                    type="number"
                                    class="mt-1 block w-full"
                                />
                                <InputError :message="form.errors.points_per_correct_score" class="mt-2" />
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-4">
                            <InputLabel for="full_width" value="Use full page width?" />
                            <Checkbox
                                id="full_width"
                                v-model:checked="form.full_width"
                            />
                            <InputError :message="form.errors.full_width" class="mt-2" />
                        </div>
                        <div class="col-span-6 sm:col-span-4">
                            <InputLabel for="active" value="Active Stage?" />
                            <Checkbox
                                id="active"
                                v-model:checked="form.active"
                            />
                            <InputError :message="form.errors.active" class="mt-2" />
                        </div>
                        <div class="col-span-6 sm:col-span-4">
                            <InputLabel for="completed" value="Completed Stage?" />
                            <Checkbox
                                id="completed"
                                v-model:checked="form.completed"
                            />
                            <InputError :message="form.errors.completed" class="mt-2" />
                        </div>
                    </div>
                </template>

                <template #footer>
                    <SecondaryButton @click="editingStage = false">
                        Cancel
                    </SecondaryButton>

                    <PrimaryButton
                        class="ms-3"
                        type="submit"
                        :class="{ 'opacity-25': form.processing }"
                        :disabled="form.processing"
                    >
                        Update Stage
                    </PrimaryButton>
                </template>
            </DialogModal>
        </form>

        <ConfirmationModal :show="deletingStage" @close="deletingStage = false">
            <template #title>
                Are you sure you want to delete the "{{ stage.name }}" stage?
            </template>
            <template #content>
                This will also delete all groups, matches and pick'ems linked to this stage. This action cannot be undone.
                Are you sure you want to delete the "{{ stage.name }}" stage?
            </template>
            <template #footer>
                <SecondaryButton @click="deletingStage = false">
                    Cancel
                </SecondaryButton>

                <DangerButton class="ms-3" @click="destroyStage">
                    Delete Stage
                </DangerButton>
            </template>
        </ConfirmationModal>
    </teleport>
</template>
