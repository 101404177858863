<script setup>
import DialogModal from "@/Components/DialogModal.vue";
import SecondaryButton from "@/Components/SecondaryButton.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import {useForm, usePage} from "@inertiajs/vue3";
import TextInput from "@/Components/TextInput.vue";
import InputLabel from "@/Components/InputLabel.vue";
import {nextTick, ref, watch} from "vue";
import InputError from "@/Components/InputError.vue";

const props = defineProps({
    showModal: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits({
    'close': () => true
})

const page = usePage();

const input = ref(null);
const form = useForm({
    name: '',
    points_per_correct_team: 1,
    points_per_correct_score: 1
});

watch(() => props.showModal, val => {
    if (val) {
        nextTick(() => {
            input.value.focus();
        })
    } else {
        form.reset();
    }
})

const createStage = () => {
    form.post(route('events.stages.store', page.props.event.slug), {
        errorBag: 'createStage',
        onSuccess: () => emit('close')
    })
}
</script>

<template>
    <form @submit.prevent="createStage">
        <DialogModal :show="showModal" @close="$emit('close')">
            <template #title>
                Create New Stage in {{ $page.props.event.name }}
            </template>

            <template #content>
                <div class="space-y-4">
                    <!-- Name -->
                    <div class="col-span-6 sm:col-span-4">
                        <InputLabel for="name" value="Stage Name" />
                        <TextInput
                            id="name"
                            v-model="form.name"
                            type="text"
                            class="mt-1 block w-full"
                            required
                            ref="input"
                        />
                        <InputError :message="form.errors.name" class="mt-2" />
                    </div>
                    <div class="col-span-6 sm:col-span-4 grid grid-cols-2 gap-4">
                        <div>
                            <InputLabel for="points_per_correct_team" value="Points per Correct Team Prediction" />
                            <TextInput
                                id="points_per_correct_team"
                                v-model="form.points_per_correct_team"
                                type="number"
                                class="mt-1 block w-full"
                            />
                            <InputError :message="form.errors.points_per_correct_team" class="mt-2" />
                        </div>
                        <div>
                            <InputLabel for="points_per_correct_score" value="Points per Correct Score Prediction" />
                            <TextInput
                                id="points_per_correct_score"
                                v-model="form.points_per_correct_score"
                                type="number"
                                class="mt-1 block w-full"
                            />
                            <InputError :message="form.errors.points_per_correct_score" class="mt-2" />
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <SecondaryButton @click="$emit('close')">
                    Cancel
                </SecondaryButton>

                <PrimaryButton
                    class="ms-3"
                    type="submit"
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                >
                    Create Stage
                </PrimaryButton>
            </template>
        </DialogModal>
    </form>
</template>
